/*
 * Module for interacting with API endpoints relating to the Citizen management feature
 */
import { getFullyQualifiedAPIUrl, apiGet } from "@/core/api";
export var apiCitizenChecks = {
    getForCitizen: function (citizenId) {
        return apiGet("citizens/".concat(citizenId, "/citizenChecks"));
    },
    getIdentityReportPdfViewLink: function (citizenId) {
        return apiGet("citizens/".concat(citizenId, "/viewToken"))
            .then(function (viewToken) { return getFullyQualifiedAPIUrl("citizens/".concat(citizenId, "/citizenChecks/identityReport/pdf?token=").concat(encodeURIComponent(viewToken))); });
    },
};
