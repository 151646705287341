import { apiGet, apiPost, apiPostVoid, getFullyQualifiedAPIUrl } from "@/core/api";
import { sessionState } from "@/core/session";
import { User } from "@minicity/core";
export var apiTasks = {
    getAssignedTasksForNetwork: function () {
        return apiGet("assignedTasks");
    },
    getAssignedTasksForPartner: function (partnerId) {
        return apiGet("partners/".concat(partnerId, "/assignedTasks"));
    },
    getAssignedTasksForPartnerUser: function (partnerId, partnerUserId) {
        return apiGet("partners/".concat(partnerId, "/partnerUsers/").concat(partnerUserId, "/assignedTasks"));
    },
    getMyAssignedTasks: function () {
        var _a, _b;
        switch ((_a = sessionState.session) === null || _a === void 0 ? void 0 : _a.user.role) {
            case User.Role.NETWORK_ADMIN: return apiTasks.getAssignedTasksForNetwork();
            case User.Role.PARTNER_ADMIN: return apiTasks.getAssignedTasksForPartner(sessionState.session.partnerId);
            case User.Role.PARTNER_USER: return apiTasks.getAssignedTasksForPartnerUser(sessionState.session.partnerId, sessionState.session.user.id);
            default: throw new Error("Unsupported user type: ".concat((_b = sessionState.session) === null || _b === void 0 ? void 0 : _b.user.role));
        }
    },
    addComment: function (taskId, message, attachments, notifyCaseworker) {
        return apiPost("tasks/".concat(taskId, "/comments"), { message: message, attachments: attachments, notifyCaseworker: notifyCaseworker });
    },
    getComments: function (taskId) {
        return apiGet("tasks/".concat(taskId, "/comments"));
    },
    getCommentAttachmentViewLink: function (taskId, commentId, attachmentId) {
        return apiGet("tasks/".concat(taskId, "/comments/viewToken"))
            .then(function (viewToken) { return getFullyQualifiedAPIUrl("tasks/".concat(taskId, "/comments/").concat(commentId, "/attachments/").concat(attachmentId, "?token=").concat(encodeURIComponent(viewToken))); });
    },
    markCompleted: function (taskId, d) {
        return apiPostVoid("tasks/".concat(taskId, ":markCompleted"), d);
    },
    submitForReview: function (taskId) {
        return apiPostVoid("tasks/".concat(taskId, ":submitForReview"), null);
    },
    cancel: function (taskId, d) {
        return apiPostVoid("tasks/".concat(taskId, ":cancel"), d);
    },
    reopen: function (taskId) {
        return apiPostVoid("tasks/".concat(taskId, ":reopen"), null);
    },
};
