import { apiGet, apiPutVoid } from "@/core/api";
import { isNetworkAdmin, sessionState } from "@/core/session";
export var apiUserSettings = {
    generateMfaSecret: function () {
        return apiGet("/auth/mfaSecret");
    },
    setMfaSecret: function (req) {
        return apiPutVoid("/auth/mfaSecret", req);
    },
    updatePreferences: function (preferences) {
        if (isNetworkAdmin()) {
            apiPutVoid("networkUsers/".concat(sessionState.session.user.id, "/preferences"), preferences);
        }
        else {
            apiPutVoid("partnerUsers/".concat(sessionState.session.user.id, "/preferences"), preferences);
        }
    }
};
