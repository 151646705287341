var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Module for interacting with API endpoints relating to the Citizen management feature
 */
import { apiDeleteVoid, apiEntityTasksAndCommentsBase, apiGet, apiPostVoid, apiPut, apiPutVoid, getFullyQualifiedAPIUrl, uploadFile } from "@/core/api";
export var apiCitizens = __assign(__assign({}, apiEntityTasksAndCommentsBase("citizens")), { getForNetwork: function () {
        return apiGet("citizens");
    }, getForPartner: function (partnerId) {
        return apiGet("partners/".concat(partnerId, "/citizens"));
    }, getForCaseworker: function (partnerId, caseworkerId) {
        return apiGet("partners/".concat(partnerId, "/partnerUsers/").concat(caseworkerId, "/citizens"));
    }, get: function (citizenId) {
        return apiGet("citizens/".concat(citizenId));
    }, setProfile: function (citizenId, profile) {
        return apiPutVoid("citizens/".concat(citizenId, "/profile"), profile);
    }, setCaseworker: function (citizenId, caseworkerId) {
        return apiPutVoid("citizens/".concat(citizenId, "/caseworker"), caseworkerId, "text/plain");
    }, setLiftHmisId: function (citizenId, liftHmisId) {
        return apiPutVoid("citizens/".concat(citizenId, "/liftHmisId"), liftHmisId, "text/plain");
    }, setPassword: function (citizenId, password) {
        return apiPutVoid("citizens/".concat(citizenId, "/password"), password, "text/plain");
    }, uploadAttachment: function (citizenId, type, file) {
        return uploadFile(file)
            .then(function (document) { return apiPut("citizens/".concat(citizenId, "/attachments/").concat(type), document); });
    }, deleteAttachment: function (citizenId, attachmentId) {
        return apiDeleteVoid("citizens/".concat(citizenId, "/attachments/").concat(attachmentId));
    }, getAttachmentViewLink: function (citizenId, attachmentId) {
        return apiGet("citizens/".concat(citizenId, "/viewToken"), "text/plain")
            .then(function (viewToken) { return getFullyQualifiedAPIUrl("citizens/".concat(citizenId, "/attachments/").concat(attachmentId, "?token=").concat(encodeURIComponent(viewToken))); });
    }, sendNotification: function (citizenId, useEmail, useSMS, subject, body) {
        return apiPostVoid("citizens/".concat(citizenId, "/notifications"), {
            useEmail: useEmail,
            useSMS: useSMS,
            subject: subject,
            body: body
        });
    } });
