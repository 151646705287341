/*
 * Helper for fetching data / making API calls, or any other async operations.
 * Keeps tracks of whether the operation is currently in-progress, and any returned value or error state.
 *
 * Usage: Call useAsync, parameterized by the type of thing (T) which the async operation you intend to use will return.
 * This returns an object containing:
 *   "status" - indicator for the status of the async operation (idle / loading / ready / error)
 *   "data" - result of the async operation (populated only when status is 'ready')
 *   "error" - any error message which occurred when attempting to perform the operation (populated only when status is 'error')
 *   "fetch" - a function which you can call, passing in any Promise<T> (e.g. the promise associated with some asynchronous call)

 */
import { notifyNetworkError } from "@/core/utils";
import { reactive } from "vue";
export function useAsync() {
    // Wraps the promise return value, along with info about current loading state / errors
    var state = reactive({
        status: "idle",
        data: null,
        error: null,
        fetch: function (promise, errorMessage) {
            state.status = "loading";
            state.error = null;
            promise
                .then(function (resp) {
                state.data = resp;
                state.status = "ready";
            })
                .catch(function (err) {
                state.error = err;
                state.status = "error";
                if (errorMessage) {
                    notifyNetworkError(errorMessage, err);
                }
            });
            // Return the promise, so the caller can chain their own .then() etc
            return promise;
        }
    });
    return state;
}
