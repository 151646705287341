/*
 * Helper for form field validation.
 * Allows a set of validation predicates to be attached to a field (e.g. "notEmpty", or any user-defined validation predicate function).
 * Can be used to determine if any individual field is valid, as well as checking all fields in a group (e.g. a form) simultaneously.
 *
 * This is designed to work conveniently with <Form...Field> components
 *
 * Usage: Call useValidation(). This returns an object containing two things:
 *   "areAllFieldsValid" - a ref<boolean> which indicates whether all fields associated with this validator are valid
 *   "validator" - a function which you can call, passing in a set of predicates.
 *                 This in turn returns another function, which can be called to check a particular field value against those predicates.
 *                 Calling this function also updates "areAllFieldsValid"/
 *
 * e.g.
 * const {areAllFieldsValid, myValidator} = useValidation();  // Effectively creates a "validation group"
 * const myCustomValidation = (f:any) => f != "badValue";   // Defines a custom validation
 *
 * // In the template
 * <FormTextField v-model="someField" :validator="myValidator(Validations.notEmpty)">
 * <FormTextField v-model="someOtherField" :validator="myValidator(Validations.notEmpty, myCustomValidation)">
 *
 * // Check that all fields are valid
 * if (areAllFieldsValid.value) { ... }
 */
import { ref } from "vue";
// Standard validation predicates
export var Validations = {
    notEmpty: function (val) {
        if (val == null || val == undefined)
            return false;
        if (val.length == 0)
            return false;
        return true;
    },
    number: function (val) {
        return !isNaN(parseFloat(String(val)));
    },
    integer: function (val) {
        var asFloat = parseFloat(String(val));
        return !isNaN(asFloat) && asFloat == parseInt(String(val), 10);
    },
    email: function (val) {
        return String(val)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) != null;
    },
    phone_US: function (val) {
        var stripped = String(val).replace('-', '');
        return Validations.length(10)(stripped) && Validations.digits(val);
    },
    digits: function (val) {
        return String(val).match(/^[0-9]*$/) != null;
    },
    length: function (len) {
        return function (val) { return String(val).length == len; };
    },
};
export function useValidation() {
    var invalidFields = new Set();
    var areAllFieldsValid = ref(false);
    var validator = function () {
        var predicates = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            predicates[_i] = arguments[_i];
        }
        // Calling validator() with a set of predicates creates a function, which in turn returns true if all predicates are true.
        return function (fieldId, fieldValue) {
            // Check to see if this field is valid
            var fieldIsValid = true;
            for (var _i = 0, predicates_1 = predicates; _i < predicates_1.length; _i++) {
                var predicate = predicates_1[_i];
                if (!predicate(fieldValue)) {
                    fieldIsValid = false;
                    break;
                }
            }
            // Check to see if all fields are now valid, and update tracking ref
            if (fieldIsValid) {
                invalidFields.delete(fieldId);
            }
            else {
                invalidFields.add(fieldId);
            }
            areAllFieldsValid.value = invalidFields.size == 0;
            return fieldIsValid;
        };
    };
    return { areAllFieldsValid: areAllFieldsValid, validator: validator };
}
