var _a;
export var RequestStatus;
(function (RequestStatus) {
    RequestStatus["NEW"] = "NEW";
    RequestStatus["DOCS_REQUIRED"] = "DOCS_REQUIRED";
    RequestStatus["NOTARY_REQUIRED"] = "NOTARY_REQUIRED";
    RequestStatus["READY_TO_SUBMIT"] = "READY_TO_SUBMIT";
    RequestStatus["SUBMITTED"] = "SUBMITTED";
    RequestStatus["APPOINTMENT_REQUIRED"] = "APPOINTMENT_REQUIRED";
    RequestStatus["CANCELLED"] = "CANCELLED";
    RequestStatus["APPROVED"] = "APPROVED";
    RequestStatus["REJECTED"] = "REJECTED";
    RequestStatus["DELIVERED"] = "DELIVERED";
})(RequestStatus || (RequestStatus = {}));
export var requestStatusLabels = (_a = {},
    _a[RequestStatus.NEW] = "New",
    _a[RequestStatus.DOCS_REQUIRED] = "Documents Required",
    _a[RequestStatus.NOTARY_REQUIRED] = "Notary Required",
    _a[RequestStatus.READY_TO_SUBMIT] = "Ready To Submit",
    _a[RequestStatus.SUBMITTED] = "Submitted",
    _a[RequestStatus.APPOINTMENT_REQUIRED] = "Appointment Required",
    _a[RequestStatus.CANCELLED] = "Cancelled",
    _a[RequestStatus.APPROVED] = "Approved",
    _a[RequestStatus.REJECTED] = "Rejected",
    _a[RequestStatus.DELIVERED] = "Delivered",
    _a);
