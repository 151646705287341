var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { apiApplicationsBase } from "@/features/applications/api";
import { apiPut, uploadFile } from "@/core/api";
export var apiGeorgiaBenefits = __assign(__assign({}, apiApplicationsBase("georgiaDfcsBenefits")), { uploadSignature: function (requestId, signatureType, file) {
        return uploadFile(file, ['IMAGE'])
            .then(function (document) { return apiPut("applications/georgiaDfcsBenefits/".concat(requestId, "/signatures/").concat(signatureType), document); });
    } });
