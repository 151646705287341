export var PartnerPlan;
(function (PartnerPlan) {
    PartnerPlan["STANDARD"] = "STANDARD";
    PartnerPlan["PRO"] = "PRO";
    PartnerPlan["A_LA_CARTE"] = "A_LA_CARTE";
    PartnerPlan["NO_FEE"] = "NO_FEE";
})(PartnerPlan || (PartnerPlan = {}));
export var isValidImage = function (d) {
    return d && d.filename && (d.filename.toLowerCase().endsWith(".png") || d.filename.toLowerCase().endsWith(".jpg") || d.filename.toLowerCase().endsWith(".jpeg"));
};
