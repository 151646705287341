/*
 * Module for interacting with API endpoints relating to the Citizen management feature
 */
import { apiDeleteVoid, apiEntityTasksAndCommentsBase, apiGet, apiPost, apiPutVoid } from "@/core/api";
export var apiCitizenInterventions = {
    getForCitizen: function (citizenId) {
        return apiGet("citizens/".concat(citizenId, "/interventions"));
    },
    create: function (citizenId, intervention) {
        return apiPost("citizens/".concat(citizenId, "/interventions"), intervention);
    },
    update: function (citizenId, intervention) {
        return apiPutVoid("citizens/".concat(citizenId, "/interventions/").concat(intervention.id), intervention);
    },
    delete: function (citizenId, interventionId) {
        return apiDeleteVoid("citizens/".concat(citizenId, "/interventions/").concat(interventionId));
    },
};
export var apiCitizenInterventionComments = function (citizenId) {
    return apiEntityTasksAndCommentsBase("citizens/".concat(citizenId, "/interventions"));
};
