export var Granularity;
(function (Granularity) {
    Granularity["By Day"] = "DAY";
    Granularity["By Month"] = "MONTH";
    Granularity["By Quarter"] = "QUARTER";
    Granularity["By Year"] = "YEAR";
})(Granularity || (Granularity = {}));
export var CitizenDimension;
(function (CitizenDimension) {
    CitizenDimension["By Age"] = "AGE";
    CitizenDimension["By Gender"] = "GENDER";
    CitizenDimension["By Race/Ethnicity"] = "RACE";
    CitizenDimension["By Birth State"] = "BIRTH_STATE";
})(CitizenDimension || (CitizenDimension = {}));
export var CitizenMeasure;
(function (CitizenMeasure) {
    CitizenMeasure["Citizens Registered"] = "CITIZENS_REGISTERED";
    CitizenMeasure["Birth Records Requested"] = "BIRTH_RECORDS_REQUESTED";
    CitizenMeasure["Birth Records Delivered"] = "BIRTH_RECORDS_DELIVERED";
    CitizenMeasure["Georgia Benefits Requested"] = "GEORGIA_DFCS_BENEFITS_REQUESTED";
    CitizenMeasure["Social Security Cards Requested"] = "SOCIAL_SECURITY_REQUESTED";
    CitizenMeasure["Estimated Benefits Value ($)"] = "ESTIMATED_BENEFITS_VALUE";
})(CitizenMeasure || (CitizenMeasure = {}));
export var ApplicationType;
(function (ApplicationType) {
    ApplicationType["Birth Records"] = "BIRTH_RECORDS";
    ApplicationType["Georgia Benefits"] = "GEORGIA_DFCS_BENEFITS";
    ApplicationType["Social Security"] = "SOCIAL_SECURITY";
})(ApplicationType || (ApplicationType = {}));
export var Program;
(function (Program) {
    Program["Lift 2.0"] = "LIFT_2_0";
})(Program || (Program = {}));
export var getCurrentPeriodNameForGranularity = function (granularity) {
    switch (granularity) {
        case Granularity["By Day"]: return "Today";
        case Granularity["By Month"]: return "This Month";
        case Granularity["By Quarter"]: return "This Quarter";
        case Granularity["By Year"]: return "This Year";
        default: return "";
    }
};
export var PALETTE = [
    '#f4efff', '#ddceff', '#c6adff', '#af8cff', '#986bff', '#7e51e6', '#623fb3', '#462d80',
    '#e8deff', '#d1bdff', '#ba9cff', '#a37bff', '#8c5aff', '#7048cc', '#543699', '#382466'
];
export var APPLICATION_STATUS_PALETTE = [
    '#ba9cff', '#8c5aff', '#66f466', '#f46666'
];
