var _a, _b;
import { RequestStatus } from "@/features/applications/model";
export var offlineStatusTransitions = (_a = {},
    _a[RequestStatus.NEW] = [RequestStatus.DOCS_REQUIRED, RequestStatus.READY_TO_SUBMIT, RequestStatus.CANCELLED],
    _a[RequestStatus.DOCS_REQUIRED] = [RequestStatus.READY_TO_SUBMIT, RequestStatus.CANCELLED],
    _a[RequestStatus.READY_TO_SUBMIT] = [RequestStatus.SUBMITTED, RequestStatus.CANCELLED],
    _a[RequestStatus.SUBMITTED] = [RequestStatus.APPOINTMENT_REQUIRED, RequestStatus.APPROVED, RequestStatus.REJECTED, RequestStatus.CANCELLED],
    _a[RequestStatus.APPOINTMENT_REQUIRED] = [RequestStatus.SUBMITTED, RequestStatus.APPROVED, RequestStatus.REJECTED, RequestStatus.CANCELLED],
    _a[RequestStatus.APPROVED] = [RequestStatus.DELIVERED, RequestStatus.CANCELLED],
    _a[RequestStatus.DELIVERED] = [RequestStatus.CANCELLED],
    _a[RequestStatus.REJECTED] = [RequestStatus.DOCS_REQUIRED, RequestStatus.READY_TO_SUBMIT, RequestStatus.CANCELLED],
    _a[RequestStatus.CANCELLED] = [RequestStatus.NEW],
    _a);
export var offlineTerminalRequestStatuses = [RequestStatus.DELIVERED, RequestStatus.REJECTED, RequestStatus.CANCELLED];
export var onlineStatusTransitions = (_b = {},
    _b[RequestStatus.NEW] = [RequestStatus.DOCS_REQUIRED, RequestStatus.READY_TO_SUBMIT, RequestStatus.CANCELLED],
    _b[RequestStatus.DOCS_REQUIRED] = [RequestStatus.READY_TO_SUBMIT, RequestStatus.CANCELLED],
    _b[RequestStatus.READY_TO_SUBMIT] = [RequestStatus.SUBMITTED, RequestStatus.CANCELLED],
    _b[RequestStatus.SUBMITTED] = [RequestStatus.APPOINTMENT_REQUIRED, RequestStatus.APPROVED, RequestStatus.REJECTED, RequestStatus.CANCELLED],
    _b[RequestStatus.APPOINTMENT_REQUIRED] = [RequestStatus.SUBMITTED, RequestStatus.APPROVED, RequestStatus.REJECTED, RequestStatus.CANCELLED],
    _b[RequestStatus.APPROVED] = [RequestStatus.DELIVERED, RequestStatus.CANCELLED],
    _b[RequestStatus.DELIVERED] = [RequestStatus.CANCELLED],
    _b[RequestStatus.REJECTED] = [RequestStatus.DOCS_REQUIRED, RequestStatus.READY_TO_SUBMIT, RequestStatus.CANCELLED],
    _b[RequestStatus.CANCELLED] = [RequestStatus.NEW],
    _b);
export var onlineTerminalRequestStatuses = [RequestStatus.DELIVERED, RequestStatus.REJECTED, RequestStatus.CANCELLED];
