var _a;
import { RequestStatus } from "@/features/applications/model";
export var statusTransitions = (_a = {},
    _a[RequestStatus.NEW] = [RequestStatus.DOCS_REQUIRED, RequestStatus.READY_TO_SUBMIT, RequestStatus.CANCELLED],
    _a[RequestStatus.DOCS_REQUIRED] = [RequestStatus.READY_TO_SUBMIT, RequestStatus.CANCELLED],
    _a[RequestStatus.READY_TO_SUBMIT] = [RequestStatus.SUBMITTED, RequestStatus.CANCELLED],
    _a[RequestStatus.SUBMITTED] = [RequestStatus.APPROVED, RequestStatus.REJECTED, RequestStatus.CANCELLED],
    _a[RequestStatus.APPROVED] = [RequestStatus.CANCELLED],
    _a[RequestStatus.REJECTED] = [RequestStatus.DOCS_REQUIRED, RequestStatus.READY_TO_SUBMIT, RequestStatus.CANCELLED],
    _a[RequestStatus.CANCELLED] = [RequestStatus.NEW],
    _a);
export var terminalRequestStatuses = [RequestStatus.APPROVED, RequestStatus.REJECTED, RequestStatus.CANCELLED];
