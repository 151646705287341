/*
 * Module for interacting with API endpoints relating to the Birth Records feature
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getFullyQualifiedAPIUrl, apiDeleteVoid, apiGet, apiPost, apiPutVoid, uploadFile, apiPut, apiEntityTasksAndCommentsBase } from "@/core/api";
// Generic generator for application APIs - can be extended / overridden by specific application types
export var apiApplicationsBase = function (resourceName) {
    return __assign(__assign({}, apiEntityTasksAndCommentsBase("applications/".concat(resourceName))), { getForNetwork: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, apiGet("applications/".concat(resourceName))];
        }); }); }, getForPartner: function (partnerId) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, apiGet("partners/".concat(partnerId, "/applications/").concat(resourceName))];
        }); }); }, getForCaseworker: function (partnerId, caseworkerId) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2 /*return*/, apiGet("partners/".concat(partnerId, "/partnerUsers/").concat(caseworkerId, "/applications/").concat(resourceName))];
        }); }); }, getRequest: function (requestId) {
            return apiGet("applications/".concat(resourceName, "/").concat(requestId));
        }, updateForm: function (requestId, formData) {
            return apiPut("applications/".concat(resourceName, "/").concat(requestId, "/formData"), formData);
        }, setStatus: function (requestId, status) {
            return apiPut("applications/".concat(resourceName, "/").concat(requestId, "/status"), status);
        }, setStatusMessage: function (requestId, message, notifyCitizen) {
            return apiPutVoid("applications/".concat(resourceName, "/").concat(requestId, "/statusMessage"), { message: message, notifyCitizen: notifyCitizen });
        }, deleteStatusMessage: function (requestId) {
            return apiDeleteVoid("applications/".concat(resourceName, "/").concat(requestId, "/statusMessage"));
        }, clone: function (requestId) {
            return apiPost("applications/".concat(resourceName, "/").concat(requestId, ":clone"), null, "text/plain");
        }, uploadSupportingDocument: function (requestId, file) {
            return uploadFile(file)
                .then(function (document) { return apiPost("applications/".concat(resourceName, "/").concat(requestId, "/supportingDocuments"), document); });
        }, uploadOverriddenPdf: function (requestId, file) {
            return uploadFile(file, ["PDF"])
                .then(function (document) { return apiPut("applications/".concat(resourceName, "/").concat(requestId, "/overriddenPdf"), document); });
        }, deleteAttachment: function (requestId, attachmentId) {
            return apiDeleteVoid("applications/".concat(resourceName, "/").concat(requestId, "/attachments/").concat(attachmentId));
        }, getAttachmentViewLink: function (requestId, attachmentId) {
            return apiGet("applications/".concat(resourceName, "/").concat(requestId, "/viewToken"), "text/plain")
                .then(function (viewToken) { return getFullyQualifiedAPIUrl("applications/".concat(resourceName, "/").concat(requestId, "/attachments/").concat(attachmentId, "?token=").concat(encodeURIComponent(viewToken))); });
        }, getPdfViewLink: function (requestId) {
            return apiGet("applications/".concat(resourceName, "/").concat(requestId, "/viewToken"))
                .then(function (viewToken) { return getFullyQualifiedAPIUrl("applications/".concat(resourceName, "/").concat(requestId, "/pdf?token=").concat(encodeURIComponent(viewToken))); });
        } });
};
