export var UserNotificationEventTypes = {
    "Tasks": {
        "weeklyTaskSummary": "Weekly Task Summary",
        "dailyOverdueTaskAlerts": "Daily Overdue Task Alerts"
    },
    "Comments": {
        "commentReplies": "New Comment / Reply"
    },
    "Citizens": {
        "citizen.registered.*": "New Citizen Registered",
    },
    "Birth Record Applications": {
        "birthRecords.applicationCreated.*": "Submitted By Citizen",
        "birthRecords.applicationStatusChanged.*": "Status Changed",
    },
    "Social Security Card Applications": {
        "socialSecurity.applicationCreated.*": "Submitted By Citizen",
        "socialSecurity.applicationStatusChanged.*": "Status Changed",
    },
    "Georgia DFCS Benefits Applications": {
        "georgiaDfcsBenefits.applicationCreated.*": "Submitted By Citizen",
        "georgiaDfcsBenefits.applicationStatusChanged.*": "Status Changed",
    }
};
