/*
 * Types broadly use throughout the entire app
 */
var _a;
import { Feature } from "@minicity/core";
import { isFeatureEnabled, isNetworkAdmin } from "./session";
export var EntityType = {
    "Citizen": "CITIZEN",
    "Birth Record": "BIRTH_RECORD_APPLICATION",
    "Georgia DFCS Benefits": "GEORGIA_DFCS_BENEFITS_APPLICATION",
    "Social Security": "SOCIAL_SECURITY_APPLICATION",
    "Partner": "PARTNER",
    "Partner User": "PARTNER_USER",
    "Partner Site": "SITE"
};
export var isEntityManagedByMe = function (entityType) {
    if (isNetworkAdmin())
        return true;
    // Partner users manage applications only if the NETWORK_PROCESSES_APPLICATIONS feature is disabled
    if (entityType == EntityType["Georgia DFCS Benefits"] || entityType == EntityType["Social Security"])
        return !isFeatureEnabled(Feature.Type.NETWORK_PROCESSES_APPLICATIONS);
    // Partner users don't manage anything else
    return false;
};
export var TaskStatus;
(function (TaskStatus) {
    TaskStatus["OPEN"] = "OPEN";
    TaskStatus["REVIEW_REQUIRED"] = "REVIEW_REQUIRED";
    TaskStatus["COMPLETED"] = "COMPLETED";
    TaskStatus["CANCELLED"] = "CANCELLED";
})(TaskStatus || (TaskStatus = {}));
export var taskStatusLabels = (_a = {},
    _a[TaskStatus.OPEN] = "Open",
    _a[TaskStatus.REVIEW_REQUIRED] = "Under Review",
    _a[TaskStatus.COMPLETED] = "Completed",
    _a[TaskStatus.CANCELLED] = "Cancelled",
    _a);
export var TaskType = {
    "Task": "CUSTOM",
    "Review Required": "MANUAL_REVIEW",
    "Notarization Required": "NOTARY",
    "Amendment / Correction Required": "AMENDMENT",
    "Submission": "SUBMISSION",
    "Delivery": "DELIVERY",
    "LIFT 2.0 - TB Testing": "LIFT_2_0_TB_TESTING",
    "LIFT 2.0 - Upload TB Test To HMIS": "LIFT_2_0_TB_TESTING_HMIS_ENTRY",
    "LIFT 2.0 - Order Birth Record": "LIFT_2_0_BIRTH_RECORD_ORDER",
    "LIFT 2.0 - Upload Birth Record To HMIS": "LIFT_2_0_BIRTH_RECORD_HMIS_ENTRY",
    "LIFT 2.0 - Order Social Security Card": "LIFT_2_0_SOCIAL_SECURITY_ORDER",
    "LIFT 2.0 - Upload Social Security Card To HMIS": "LIFT_2_0_SOCIAL_SECURITY_HMIS_ENTRY",
    "LIFT 2.0 - Order State ID": "LIFT_2_0_STATE_ID_ORDER",
    "LIFT 2.0 - Obtain Approved State ID": "LIFT_2_0_STATE_ID_RECEIVE",
    "LIFT 2.0 - Upload State ID To HMIS": "LIFT_2_0_STATE_ID_HMIS_ENTRY",
    "LIFT 2.0 - Achieve Full Document Readiness": "LIFT_2_0_DOCUMENT_READINESS",
    "LIFT 2.0 - Record Program Exit In HMIS": "LIFT_2_0_PROGRAM_EXIT",
};
export var TaskAudience = {
    "Mini City Admin": "NETWORK_USER",
    "Case Worker": "PARTNER_USER",
};
export var isTaskAssignedToMe = function (t) {
    if (isNetworkAdmin())
        return t.audience == "NETWORK_USER" || t.status == TaskStatus.REVIEW_REQUIRED;
    return t.audience == "PARTNER_USER";
};
export var doINeedToActionTask = function (t) {
    if (!isTaskAssignedToMe(t))
        return false;
    if (isNetworkAdmin() && t.status == TaskStatus.REVIEW_REQUIRED)
        return true;
    return t.status == TaskStatus.OPEN;
};
