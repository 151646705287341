var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Site } from "@minicity/core";
import { apiDeleteVoid, apiEntityTasksAndCommentsBase, apiGet, apiPost, apiPut } from "@/core/api";
export var apiSites = __assign(__assign({}, apiEntityTasksAndCommentsBase("sites")), { getForNetwork: function () {
        return apiGet("sites");
    }, getForPartner: function (partnerId) {
        return apiGet("partners/".concat(partnerId, "/sites"));
    }, get: function (siteId) {
        return apiGet("sites/".concat(siteId));
    }, update: function (site) {
        return apiPut("sites/".concat(site.id), site);
    }, addSitePartnerUserAssignment: function (siteId, partnerUserId, role) {
        return apiPut("sites/".concat(siteId, "/partnerUserAssignments/").concat(partnerUserId, "/").concat(Site.PartnerUserRole[role]), null);
    }, deleteSitePartnerUserAssignment: function (siteId, partnerUserId, role) {
        return apiDeleteVoid("sites/".concat(siteId, "/partnerUserAssignments/").concat(partnerUserId, "/").concat(Site.PartnerUserRole[role]));
    }, create: function (partnerId, site) {
        return apiPost("partners/".concat(partnerId, "/sites"), site);
    } });
