<!--
  A Bulma-styled loading spinner to be used when table content is loading
-->
<template>
  <td colspan=999 style="text-align: center">
    <LoadingSpinner size="medium" style="margin-bottom: 10px; margin-top: 15px"/>
    <h1 class="subtitle">Loading...</h1>
  </td>
</template>

<script lang="ts" setup>
</script>

<style scoped>
</style>