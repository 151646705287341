var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { apiDeleteVoid, apiEntityTasksAndCommentsBase, apiGet, apiPut, apiPutVoid, getFullyQualifiedAPIUrl, uploadFile } from "@/core/api";
export var apiPartners = __assign(__assign({}, apiEntityTasksAndCommentsBase("partners")), { getForNetwork: function () {
        return apiGet("partners");
    }, get: function (partnerId) {
        return apiGet("partners/".concat(partnerId));
    }, setProfile: function (partnerId, profile) {
        return apiPutVoid("partners/".concat(partnerId, "/profile"), profile);
    }, setPreferences: function (partnerId, preferences) {
        return apiPutVoid("partners/".concat(partnerId, "/preferences"), preferences);
    }, setAdminSettings: function (partnerId, adminSettings) {
        return apiPutVoid("partners/".concat(partnerId, "/adminSettings"), adminSettings);
    }, setShownStatus: function (partnerId, shown) {
        return apiPutVoid("partners/".concat(partnerId, "/shown"), shown);
    }, uploadAttachment: function (partnerId, type, file) {
        return uploadFile(file)
            .then(function (document) { return apiPut("partners/".concat(partnerId, "/attachments/").concat(type), document); });
    }, deleteAttachment: function (partnerId, attachmentId) {
        return apiDeleteVoid("partners/".concat(partnerId, "/attachments/").concat(attachmentId));
    }, getAttachmentViewLink: function (partnerId, attachmentId) {
        return apiGet("partners/".concat(partnerId, "/viewToken"), "text/plain")
            .then(function (viewToken) { return getFullyQualifiedAPIUrl("partners/".concat(partnerId, "/attachments/").concat(attachmentId, "?token=").concat(encodeURIComponent(viewToken))); });
    } });
