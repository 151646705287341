/*
 * General-purpose utility functions
 */
import { sessionState } from "./session";
/*
 * Date / time formatting functions.
 *
 * Will use the user's specified timezone from preferences, or fall back to US East-coast time if unavailable
 */
import moment from "moment-timezone";
export var formatDate = function (d) {
    return toPreferredTimezone(d).format("MMMM D, YYYY");
};
export var formatDateShort = function (d) {
    return toPreferredTimezone(d).format("MMM D, YYYY");
};
export var formatDateTime = function (d) {
    return toPreferredTimezone(d).format("MMMM D, YYYY HH:mm");
};
export var formatTime = function (d) {
    return toPreferredTimezone(d).format("HH:mm");
};
export var formatTime12Hour = function (d) {
    return toPreferredTimezone(d).format("hh:mm A");
};
function toPreferredTimezone(d) {
    var _a;
    var preferredTZ = (_a = sessionState.session) === null || _a === void 0 ? void 0 : _a.userPreferences.timezone;
    if (preferredTZ) {
        return moment.utc(d).tz(preferredTZ);
    }
    else {
        return moment.utc(d).local();
    }
}
export function fromPreferredTimezoneToUTC(s, format) {
    var _a;
    var preferredTZ = (_a = sessionState.session) === null || _a === void 0 ? void 0 : _a.userPreferences.timezone;
    if (preferredTZ) {
        return format ? moment.tz(s, format, preferredTZ).utc().valueOf()
            : moment.tz(s, preferredTZ).utc().valueOf();
    }
    else {
        return moment(s, format).utc().valueOf();
    }
}
export var timestampToYYYYMMDD = function (ts) {
    return new Date(ts).toISOString().slice(0, 10);
};
export var timestampToHHMMSS = function (ts) {
    return new Date(ts).toISOString().slice(11, 19);
};
export var YYYYMMDDToTimestamp = function (s) {
    return new Date(s).valueOf();
};
export var formatCount = function (quantity, countingWord) {
    return quantity == 1 ? "1 ".concat(countingWord) : "".concat(quantity, " ").concat(countingWord, "s");
};
export var formatTimeDifference = function (d1, d2) {
    var millis = Math.abs(d2 - d1);
    var seconds = millis / 1000;
    if (seconds < 60)
        return formatCount(Math.round(seconds), "second");
    var minutes = seconds / 60;
    if (minutes < 60)
        return formatCount(Math.round(minutes), "minute");
    var hours = minutes / 60;
    if (hours < 72)
        return formatCount(Math.round(hours), "hour");
    var days = hours / 24;
    return formatCount(Math.round(days), "day");
};
export var formatTimeRelativeToNow = function (d) {
    var now = Date.now();
    var diffString = formatTimeDifference(d, now);
    return now > d ? "".concat(diffString, " ago") : "in ".concat(diffString);
};
// Checks that a string value includes every term within a supplied set. Ignores case.
export var matchesTerms = function (val, terms) {
    if (terms.length == 0)
        return true;
    var valLC = val.toLowerCase();
    for (var _i = 0, terms_1 = terms; _i < terms_1.length; _i++) {
        var term = terms_1[_i];
        if (!valLC.includes(term.toLowerCase()))
            return false;
    }
    return true;
};
export var enumKey = function (val, enumObj) {
    return Object.keys(enumObj)[Object.values(enumObj).indexOf(val)];
};
export var trimString = function (s) {
    if (!s)
        return "";
    return s.trim();
};
/*
 * Error / notification toast popups.
 * These will automatically inject themselves into the DOM, so can be used without any template changes.
 */
import { toast } from "bulma-toast";
import { CONFIG, STATIC_CONFIG } from "./config";
export var notifyError = function (error, duration) {
    if (error.length > 512) {
        error = error.substring(0, 512) + "...";
    }
    toast({ message: error, type: 'is-info', position: 'bottom-center', dismissible: true, extraClasses: 'bulmaNotification', duration: duration || 5000 });
};
export var notifyNetworkError = function (msg, err) {
    console.error(err);
    notifyError(err ? "".concat(msg, " - ").concat(mapHttpError(err).message) : msg);
};
export var notifySuccess = function (message) {
    toast({ message: message, type: 'is-success', position: 'bottom-center', extraClasses: 'bulmaNotification' });
};
export var mapHttpError = function (err) {
    var _a;
    console.log("HTTP error", err);
    switch (err.code) {
        case "ERR_BAD_REQUEST": {
            switch (err.response.status) {
                case 400: {
                    if (err.response.data == "An error has occurred") {
                        // Generic error
                        return new Error("Invalid input - please check the information you have provided");
                    }
                    else {
                        // Error with more context for the user
                        return new Error(err.response.data);
                    }
                }
                case 401: {
                    if (err.response.data == "HTTP 401 Unauthorized") {
                        // Generic error
                        return new Error("You do not have permission to access this");
                    }
                    else {
                        // Error with more context for the user
                        return new Error(err.response.data);
                    }
                }
                case 404: return new Error("Not found - this may have been deleted");
                case 429: return new Error("You are interacting too quickly - please slow down!");
                case 500: return new Error("Server error - please try again, or report this error to ".concat(((_a = CONFIG.value) === null || _a === void 0 ? void 0 : _a.networkName) || STATIC_CONFIG.DEFAULT_NETWORK_NAME));
            }
            break;
        }
        case "ERR_NETWORK": {
            return new Error("Network error - please check your internet connection and try again");
        }
    }
    return new Error("Network or server error - please check your internet connection and try again");
};
