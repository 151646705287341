var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import { STATIC_CONFIG } from "./config";
import { getAuthHeader, sessionState } from "./session";
import { notifyNetworkError, notifySuccess } from "./utils";
export var uploadFile = function (file, allowedTypes) { return __awaiter(void 0, void 0, void 0, function () {
    var formData, allowedTypesParam, uploadId;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                formData = new FormData();
                formData.append("file", file);
                allowedTypesParam = allowedTypes ? "?type=".concat(allowedTypes.join('|')) : '';
                return [4 /*yield*/, axios.post("".concat(STATIC_CONFIG.API_ROOT, "/upload").concat(allowedTypesParam), formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                        .then(function (resp) { return resp.data; }, function (err) { throw err; })];
            case 1:
                uploadId = _a.sent();
                return [2 /*return*/, { id: uploadId, filename: file.name }];
        }
    });
}); };
/*
 * Convenience wrappers for axios HTTP calls
 */
export function getFullyQualifiedAPIUrl(path) {
    var _a;
    // If path starts with a '/', treat it as an absolute path. Otherwise, prepend network ID
    return path.startsWith("/") ? "".concat(STATIC_CONFIG.API_ROOT).concat(path) : "".concat(STATIC_CONFIG.API_ROOT, "/networks/").concat((_a = sessionState.session) === null || _a === void 0 ? void 0 : _a.networkId, "/").concat(path);
}
export function apiGet(path, contentType) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.get(getFullyQualifiedAPIUrl(path), { headers: { "Content-Type": contentType || "application/json", "Authorization": getAuthHeader() } }).then(function (resp) { return resp.data; }, function (err) { throw err; })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function apiPut(path, body, contentType) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.put(getFullyQualifiedAPIUrl(path), body, { headers: { "Content-Type": contentType || "application/json", "Authorization": getAuthHeader() } }).then(function (resp) { return resp.data; }, function (err) { throw err; })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function apiPutVoid(path, body, contentType) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.put(getFullyQualifiedAPIUrl(path), body, { headers: { "Content-Type": contentType || "application/json", "Authorization": getAuthHeader() } }).then(function (resp) { }, function (err) { throw err; })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function apiPost(path, body, contentType) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post(getFullyQualifiedAPIUrl(path), body, { headers: { "Content-Type": contentType || "application/json", "Authorization": getAuthHeader() } }).then(function (resp) { return resp.data; }, function (err) { throw err; })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function apiPostVoid(path, body, contentType) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post(getFullyQualifiedAPIUrl(path), body, { headers: { "Content-Type": contentType || "application/json", "Authorization": getAuthHeader() } }).then(function (resp) { }, function (err) { throw err; })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function apiDelete(path, body, contentType) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.delete(getFullyQualifiedAPIUrl(path), { headers: { "Content-Type": contentType || "application/json", "Authorization": getAuthHeader() } }).then(function (resp) { return resp.data; }, function (err) { throw err; })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function apiDeleteVoid(path, contentType) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.delete(getFullyQualifiedAPIUrl(path), { headers: { "Content-Type": contentType || "application/json", "Authorization": getAuthHeader() } }).then(function (resp) { }, function (err) { throw err; })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export var doApiAction = function (tracker, actionName, successMessage, failureMessage, action) {
    if (tracker) {
        tracker.value = actionName;
    }
    action()
        .then(function () {
        if (successMessage) {
            notifySuccess(successMessage);
        }
    })
        .catch(function (err) {
        if (failureMessage) {
            notifyNetworkError(failureMessage, err);
        }
    })
        .finally(function () { if (tracker) {
        tracker.value = null;
    } });
};
/*
 * Can be used by any entity-specific API which wants to expose comments-and-tasks functionality
 */
export var apiEntityTasksAndCommentsBase = function (resourcePrefix) {
    return {
        addComment: function (entityId, message, attachments, notifyCaseworker) {
            return apiPost("".concat(resourcePrefix, "/").concat(entityId, "/comments"), { message: message, attachments: attachments, notifyCaseworker: notifyCaseworker });
        },
        getComments: function (entityId) {
            return apiGet("".concat(resourcePrefix, "/").concat(entityId, "/comments"));
        },
        getCommentAttachmentViewLink: function (entityId, commentId, attachmentId) {
            return apiGet("".concat(resourcePrefix, "/").concat(entityId, "/comments/viewToken"))
                .then(function (viewToken) { return getFullyQualifiedAPIUrl("".concat(resourcePrefix, "/").concat(entityId, "/comments/").concat(commentId, "/attachments/").concat(attachmentId, "?token=").concat(encodeURIComponent(viewToken))); });
        },
        addTask: function (entityId, audience, message) {
            return apiPost("".concat(resourcePrefix, "/").concat(entityId, "/tasks"), { audience: audience, message: message });
        },
        getTasks: function (entityId) {
            return apiGet("".concat(resourcePrefix, "/").concat(entityId, "/tasks"));
        },
    };
};
