/*

 */
export function watchQueryParam(r, paramName) {
    var url = new URL(window.location.toString());
    if (url.searchParams.has(paramName)) {
        r.value = url.searchParams.get(paramName);
    }
    return function () {
        var url = new URL(window.location.toString());
        url.searchParams.set(paramName, r.value);
        window.history.replaceState({}, '', url);
    };
}
