/*
 * Helper for filtering a collection based on a text search query / filter.
 * Filtering ignores case, and checks for each word in the query individually, so it's not sensitive to the exact ordering
 *
 * Usage: Call useFilter, parameterized by the type of thing (T) which you will be filtering.
 * Pass in (a) the collection T[] to be filtered, and (b) a function which maps any given T to a string for search / filter comparison.
 * This returns an object containing two things:
 *   "searchQuery" - a ref<string>, which should be bound to a text input field or similar where the user will be entering their search / filter query
 *   "filtered" - a dynamically computed view of the filtered collection
 *
 * There is also "useFilterForAsync", which works directly with the AsyncState objects returned by useAsync and handles unwrapping the data from inside that structure.
 *  *
 * e.g.
 * // Note - using unpacking here to give different names to the searchQuery and filtered, in case we're using useFilter multiple times and need to keep them separate.
 * // We could have just assigned const filterThingies = useFilter(...), and then referred to "filterThingies.searchQuery" / "filterThingies.filtered", but that gets long / verbose!
 * const {searchQuery:thingyQuery, filtered:filteredThingies} = useFilter<Thingy>(thingies, (t)=>t.name);
 * // Template or other code can now refer to "thingyQuery" (should probably be bound to a text input field) and "filteredThingies"
 */
import { computed, ref } from "vue";
import { matchesTerms } from "@/core/utils";
export function useFilter(input, mapToString, limit) {
    // Filters the input array according to the specified search terms
    var searchQuery = ref("");
    var filtered = computed(function () {
        var terms = searchQuery.value.trim().toLowerCase().split(' ');
        return !input ? [] : input.filter(function (item) { return matchesTerms(mapToString(item), terms); });
    });
    var limited = computed(function () {
        var items = filtered.value;
        return (limit && items.length > limit) ? items.slice(0, limit) : items;
    });
    return { searchQuery: searchQuery, filtered: filtered, limited: limited };
}
// This is required in order for filter computation with computed() to re-trigger when input.data is replaced
export function useFilterForAsync(input, mapToString, limit) {
    // Filters the input array according to the specified search terms
    var searchQuery = ref("");
    var filtered = computed(function () {
        var terms = searchQuery.value.trim().toLowerCase().split(' ');
        return !input.data ? [] : input.data.filter(function (item) { return matchesTerms(mapToString(item), terms); });
    });
    var limited = computed(function () {
        var items = filtered.value;
        return (limit && items.length > limit) ? items.slice(0, limit) : items;
    });
    return { searchQuery: searchQuery, filtered: filtered, limited: limited };
}
