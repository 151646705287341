/*
 * Module for tracking session state, and handling core login / logout functionality
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { reactive } from "vue";
import { STATIC_CONFIG } from "./config";
import axios from "axios";
import { apiTasks } from "@/features/tasks/api";
import { Feature, User } from "@minicity/core";
export var sessionState = reactive({
    session: undefined
});
export var getAuthHeader = function () {
    var _a;
    return "Bearer ".concat((_a = sessionState.session) === null || _a === void 0 ? void 0 : _a.id);
};
// Attempts to resume any old session from info remaining in localStorage
export var tryToResumeSession = function () { return __awaiter(void 0, void 0, Promise, function () {
    var oldSession, sessionId, session;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!localStorage["session"])
                    throw ("No session to resume");
                oldSession = JSON.parse(localStorage["session"]);
                sessionId = oldSession.id;
                console.log("Attempting to resume session");
                return [4 /*yield*/, axios.get("".concat(STATIC_CONFIG.API_ROOT, "/portal/caseworker/sessions/").concat(sessionId))
                        .then(function (resp) { return resp.data; }, function (err) { throw err; })];
            case 1:
                session = _a.sent();
                storeSession(session);
                return [2 /*return*/, session];
        }
    });
}); };
export var loginAsPartner = function (email, password, mfaOneTimeCode) { return __awaiter(void 0, void 0, Promise, function () {
    var session;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, axios.post("".concat(STATIC_CONFIG.API_ROOT, "/portal/caseworker/login/partner"), { username: email, password: password, mfaOneTimeCode: mfaOneTimeCode }, { headers: { "Content-Type": "application/json" } }).then(function (resp) { return resp.data; }, function (err) { throw err; })];
            case 1:
                session = _a.sent();
                storeSession(session);
                return [2 /*return*/, session];
        }
    });
}); };
export var loginAsNetworkAdmin = function (email, password, mfaOneTimeCode) { return __awaiter(void 0, void 0, Promise, function () {
    var session;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, axios.post("".concat(STATIC_CONFIG.API_ROOT, "/portal/caseworker/login/networkAdmin"), { username: email, password: password, mfaOneTimeCode: mfaOneTimeCode }, { headers: { "Content-Type": "application/json" } }).then(function (resp) { return resp.data; }, function (err) { throw err; })];
            case 1:
                session = _a.sent();
                storeSession(session);
                return [2 /*return*/, session];
        }
    });
}); };
export var proxyLoginAsPartnerUser = function (partnerUserId) { return __awaiter(void 0, void 0, Promise, function () {
    var session;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, axios.post("".concat(STATIC_CONFIG.API_ROOT, "/portal/caseworker/login/partner/").concat(partnerUserId, ":proxy"), null, { headers: {
                        "Content-Type": "application/json",
                        "Authorization": getAuthHeader()
                    } }).then(function (resp) { return resp.data; }, function (err) { throw err; })];
            case 1:
                session = _a.sent();
                storeSession(session);
                return [2 /*return*/, session];
        }
    });
}); };
export var proxyLoginAsCitizenUser = function (citizenId) { return __awaiter(void 0, void 0, Promise, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, axios.post("".concat(STATIC_CONFIG.API_ROOT, "/portal/caseworker/login/citizen/").concat(citizenId, ":proxy"), null, { headers: {
                        "Content-Type": "application/json",
                        "Authorization": getAuthHeader()
                    } }).then(function (resp) { return resp.data; }, function (err) { throw err; })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var initiatePasswordReset = function (email) { return __awaiter(void 0, void 0, Promise, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, axios.post("".concat(STATIC_CONFIG.API_ROOT, "/portal/caseworker/passwordReset"), { email: email }, { headers: { "Content-Type": "application/json" } })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
export var validatePasswordReset = function (userType, email, token) { return __awaiter(void 0, void 0, Promise, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, axios.post("".concat(STATIC_CONFIG.API_ROOT, "/portal/caseworker/passwordReset/").concat(userType, ":validate"), { email: email, token: token }, { headers: { "Content-Type": "application/json" } })
                    .then(function () { return true; })
                    .catch(function (err) {
                    if (err.response.status == 404) {
                        return false; // 404 indicates invalid token
                    }
                    else {
                        throw err;
                    }
                })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var applyPasswordReset = function (userType, email, token, password) { return __awaiter(void 0, void 0, Promise, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, axios.post("".concat(STATIC_CONFIG.API_ROOT, "/portal/caseworker/passwordReset/").concat(userType), { email: email, password: password, token: token }, { headers: { "Content-Type": "application/json" } })];
            case 1:
                _a.sent();
                switch (userType) {
                    case 'partner': return [2 /*return*/, loginAsPartner(email, password)];
                    case 'networkAdmin': return [2 /*return*/, loginAsNetworkAdmin(email, password)];
                }
                return [2 /*return*/];
        }
    });
}); };
// Store session info in local storage
var storeSession = function (session) {
    console.log("Storing session");
    sessionState.session = session;
    localStorage["session"] = JSON.stringify(session);
    sessionState.cachedPartnerList = undefined;
    // Load tasks
    sessionState.myAssignedTasks = undefined;
    assignedTasksRefreshTimer();
};
// Poll for the current user's assigned tasks every 5 minutes
var assignedTasksRefreshTimer = function () {
    if (!sessionState.session)
        return;
    refreshMyAssignedTasks();
    setTimeout(assignedTasksRefreshTimer, 300000);
};
export var refreshMyAssignedTasks = function () {
    var _a;
    (_a = apiTasks.getMyAssignedTasks()) === null || _a === void 0 ? void 0 : _a.then(function (tasks) { return sessionState.myAssignedTasks = tasks; });
};
export var endSession = function () {
    if (sessionState.session) {
        console.log("Ending session");
        axios.delete("".concat(STATIC_CONFIG.API_ROOT, "/sessions/").concat(sessionState.session.id));
    }
    clearLocalSession();
};
export var clearLocalSession = function () {
    console.log("Clearing session data");
    sessionState.session = undefined;
    delete localStorage["session"];
    sessionState.cachedPartnerList = undefined;
};
// Determines whether the specified module / feature is enabled for this user
export var isFeatureEnabled = function (id) {
    if (!sessionState.session)
        return false;
    if (sessionState.session.user.role == User.Role.NETWORK_ADMIN)
        return true;
    return sessionState.session.partnerSettings.enabledModules[id] == "ENABLED";
};
export var canSeeFeature = function (id) {
    if (!sessionState.session)
        return false;
    if (sessionState.session.user.role == User.Role.NETWORK_ADMIN)
        return true;
    switch (id) {
        case "partners":
            return [User.Role.NETWORK_ADMIN].includes(sessionState.session.user.role);
        case "sites":
        case "partnerUsers":
            return isFeatureEnabled(Feature.Type.SITES) && [User.Role.NETWORK_ADMIN, User.Role.PARTNER_ADMIN].includes(sessionState.session.user.role);
        case "citizens":
            return isFeatureEnabled(Feature.Type.CITIZENS);
        case "birthRecords":
            return isFeatureEnabled(Feature.Type.BIRTH_RECORDS);
        case "socialSecurity":
            return isFeatureEnabled(Feature.Type.SOCIAL_SECURITY);
        case "georgiaBenefits":
            return isFeatureEnabled(Feature.Type.GEORGIA_DFCS_BENEFITS);
        case "analytics":
            return isFeatureEnabled(Feature.Type.ANALYTICS) && [User.Role.NETWORK_ADMIN, User.Role.PARTNER_ADMIN].includes(sessionState.session.user.role);
        case "tasks":
            return isFeatureEnabled(Feature.Type.TASKS);
        case "lift_2_0":
            return isFeatureEnabled(Feature.Type.LIFT_2_0);
        default:
            return false;
    }
};
export var isPartnerAdmin = function () {
    if (!sessionState.session)
        return false;
    return sessionState.session.user.role == User.Role.PARTNER_ADMIN;
};
export var isNetworkAdmin = function () {
    if (!sessionState.session)
        return false;
    return sessionState.session.user.role == User.Role.NETWORK_ADMIN;
};
