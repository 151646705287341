var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { apiDeleteVoid, apiEntityTasksAndCommentsBase, apiGet, apiPostVoid, apiPut, apiPutVoid, getFullyQualifiedAPIUrl, uploadFile } from "@/core/api";
export var apiPartnerUsers = __assign(__assign({}, apiEntityTasksAndCommentsBase("partnerUsers")), { getForNetwork: function () {
        return apiGet("partnerUsers");
    }, getForPartner: function (partnerId) {
        return apiGet("partners/".concat(partnerId, "/partnerUsers"));
    }, get: function (partnerUserId) {
        return apiGet("partnerUsers/".concat(partnerUserId));
    }, setDisplayName: function (partnerUserId, displayName) {
        return apiPutVoid("partnerUsers/".concat(partnerUserId, "/displayName"), displayName, "text/plain");
    }, setUserRole: function (partnerUserId, role) {
        return apiPutVoid("partnerUsers/".concat(partnerUserId, "/role"), role, "text/plain");
    }, setEmail: function (partnerUserId, email) {
        return apiPutVoid("partnerUsers/".concat(partnerUserId, "/email"), email, "text/plain");
    }, setProfile: function (partnerUserId, profile) {
        return apiPutVoid("partnerUsers/".concat(partnerUserId, "/profile"), profile);
    }, setPreferences: function (partnerUserId, preferences) {
        return apiPutVoid("partnerUsers/".concat(partnerUserId, "/preferences"), preferences);
    }, setAssignedAmbassadors: function (partnerUserId, ambassadors) {
        return apiPutVoid("partnerUsers/".concat(partnerUserId, "/assignedAmbassadors"), ambassadors);
    }, setShownStatus: function (partnerUserId, shown) {
        return apiPutVoid("partnerUsers/".concat(partnerUserId, "/shown"), shown);
    }, reassignAllCitizens: function (fromPartnerUserId, toPartnerUserId) {
        return apiPostVoid("partnerUsers/".concat(fromPartnerUserId, ":reassignAllCitizens"), toPartnerUserId, "text/plain");
    }, inviteToPartnerPortal: function (partnerUserId) {
        return apiPostVoid("partnerUsers/".concat(partnerUserId, ":inviteToPartnerPortal"), null, "text/plain");
    }, uploadAttachment: function (partnerUserId, type, file) {
        return uploadFile(file)
            .then(function (document) { return apiPut("partnerUsers/".concat(partnerUserId, "/attachments/").concat(type), document); });
    }, deleteAttachment: function (partnerUserId, attachmentId) {
        return apiDeleteVoid("partnerUsers/".concat(partnerUserId, "/attachments/").concat(attachmentId));
    }, getAttachmentViewLink: function (partnerUserId, attachmentId) {
        return apiGet("partnerUsers/".concat(partnerUserId, "/viewToken"), "text/plain")
            .then(function (viewToken) { return getFullyQualifiedAPIUrl("partnerUsers/".concat(partnerUserId, "/attachments/").concat(attachmentId, "?token=").concat(encodeURIComponent(viewToken))); });
    } });
