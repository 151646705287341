var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * Module for interacting with API endpoints relating to the Birth Records feature
 */
import { apiApplicationsBase } from "@/features/applications/api";
import { apiPost } from "@/core/api";
export var apiBirthRecords = __assign(__assign({}, apiApplicationsBase("birthRecords")), { sendViaMail: function (applicationId) {
        return apiPost("applications/birthRecords/".concat(applicationId, ":sendViaMail"), null);
    } });
